body.is-shitcoin {
  background-image: url(img/ptsdchihu.jpg);
  overflow: hidden;
}
body.not-shitcoin {
  background-image: url(img/murrayawesome.jpg);
  overflow: hidden;
}

form {
  min-height: 180px;
}

.not-shitcoin .button-result-not-shitcoin {
  display: block;
}
form .button-result-not-shitcoin,
.not-shitcoin .button-do-check {
  display: none;
}


.is-shitcoin .App,
.not-shitcoin .App {
  animation: lowerOpacity 2s forwards;
  animation-delay: 1s;
}
@keyframes lowerOpacity {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.85;
  }
}